import { graphql } from "gatsby";
import React from "react";
import { Layout, ScrollToTopBtn } from "../components";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Props } from "../models/props";
import { htmlToReact } from "../utils";

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String, $strapiId: Int) {
    sitePage(path: { eq: $url }) {
      id
    }
    page(strapiId: { eq: $strapiId }) {
      id
      image_background {
        id
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;

const Page = ({ data, pageContext, ...props }: Props): JSX.Element => {
  const backgroundImage = data?.page?.image_background?.localFile;
  const title = pageContext.frontmatter?.title;
  const subtitle = pageContext.frontmatter?.subtitle;
  const html = pageContext.html;
  return (
    <Layout {...props} site={pageContext.site} page={pageContext}>
      <Header
        site={pageContext.site}
        page={pageContext}
        image={backgroundImage}
      />
      <div id="content" className="site-content">
        <main id="main" className="site-main inner">
          <article className="post page post-full">
            <header className="post-header">
              <h1 className="post-title">{title}</h1>
            </header>
            {subtitle && (
              <div className="post-subtitle">{htmlToReact(subtitle)}</div>
            )}
            <div className="post-content">{htmlToReact(html)}</div>
          </article>
        </main>
        <ScrollToTopBtn />
        <Footer site={pageContext.site} />
      </div>
    </Layout>
  );
};
export default Page;
